import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { ThemingService } from '../core/theming.service';
import { ThemeDialogComponent } from '../editor/theme-dialog/theme-dialog.component';
import { ThemeModel } from '../shared/models/theme.model';
import { SvgPreviewComponent } from '../shared/svg-preview/svg-preview.component';

@Component({
  selector: 'lsf-themes-theme-picker',
  templateUrl: './theme-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatButtonModule, MatMenuModule, MatIconModule, MatDividerModule, NgFor, SvgPreviewComponent, MatTooltip],
})
export class ThemePickerComponent implements OnInit {
  themes: ThemeModel[] = [];
  selectedTheme: ThemeModel | null = null;

  @Input() label: string | null = null;
  @Input() activeThemeOnChange = true;
  @Input() editProjectTheme = false;
  @Input() set theme(theme) {
    this.selectedTheme = !theme ? this.themingService.activeTheme : !theme.isOrphan ? theme : null;
    this.changeDetectorRef.markForCheck();
    this._theme = theme;
    if (!theme && this.selectedTheme) {
      this.changeTheme.emit(this.selectedTheme);
    }
  }

  get theme() {
    return this._theme;
  }

  @Output() changeTheme: EventEmitter<ThemeModel> = new EventEmitter<ThemeModel>();
  @Output() editTheme: EventEmitter<ThemeModel> = new EventEmitter<ThemeModel>();

  private _theme?: ThemeModel;

  constructor(
    private matDialog: MatDialog,
    private themingService: ThemingService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.themes = this.themingService.themes;
    if (!this.selectedTheme) {
      this.selectedTheme = this.theme ? this.theme : this.themingService.activeTheme;
    }
  }

  onAddTheme() {
    ThemeDialogComponent.open(this.matDialog, { maxWidth: 900, maxHeight: '90vh' });
  }

  async onEditTheme() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = 900;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.data = { editProjectTheme: this.editProjectTheme, theme: this.selectedTheme };
    const theme = await ThemeDialogComponent.open(this.matDialog, dialogConfig);
    if (theme) {
      this.editTheme.emit(theme);
    }
  }

  onSetTheme(theme: ThemeModel) {
    this.selectedTheme = theme;
    if (this.activeThemeOnChange) {
      this.themingService.activeTheme = theme;
    }
    this.changeTheme.emit(theme);
  }
}
