import { ChangeDetectionStrategy, Component, input, model, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxColorsModule, NgxColorsTriggerDirective } from 'ngx-colors';
import { PanelComponent } from 'ngx-colors/lib/components/panel/panel.component';
import { asyncScheduler } from 'rxjs';
import { TextColorPipe } from '../../shared/pipes/text-color.pipe';

@Component({
  selector: 'lsf-themes-editor-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, TextColorPipe, NgxColorsModule],
})
export class ColorPickerComponent {
  presets = input<string[]>([]);
  color = model<string>('#ffffff');
  ngxColor = viewChild.required<NgxColorsTriggerDirective>(NgxColorsTriggerDirective);

  onColorPickerOpen(): void {
    if (this.ngxColor().palette.length) {
      return;
    }

    const panel: PanelComponent = this.ngxColor().panelRef.instance;
    panel.menu = 3;
    const hideBackButton = () => panel.panelRef.nativeElement.querySelector('.nav-wrapper .round-button').classList.add('d-none');
    asyncScheduler.schedule(() => hideBackButton());
  }
}
