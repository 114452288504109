import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, Inject, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogConfig,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { UpdateThemeModel } from '@shared/data-access/common';
import { CloseOnEscapeDirective } from '@shared/ui/dialog';
import { firstValueFrom } from 'rxjs';
import { ThemingService } from '../../core/theming.service';
import { ThemeModel } from '../../shared/models/theme.model';
import { ThemeEditorService } from '../theme-editor.service';
import { WizardComponent } from '../wizard/wizard.component';

@Component({
  selector: 'lsf-themes-editor-dialog',
  templateUrl: './theme-dialog.component.html',
  styleUrls: ['./theme-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CloseOnEscapeDirective,
    MatDialogTitle,
    MatDialogContent,
    WizardComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    NgTemplateOutlet,
  ],
})
export class ThemeDialogComponent implements AfterViewInit {
  @ViewChild(WizardComponent) wizard: WizardComponent = null!;
  wizardActionButtons: TemplateRef<unknown> = null!;
  currentTheme: ThemeModel;
  creating = true;
  editProjectTheme = false;

  constructor(
    private dialogRef: MatDialogRef<ThemeDialogComponent>,
    private themeEditorService: ThemeEditorService,
    private themingService: ThemingService,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) data?: { editProjectTheme: boolean; theme: ThemeModel },
  ) {
    if (data) {
      this.editProjectTheme = data.editProjectTheme;
      this.currentTheme = data.theme;
      this.creating = false;
    } else {
      this.currentTheme = this.themeEditorService.createThemeFromDefault();
    }
  }

  static async open(dialog: MatDialog, config: MatDialogConfig<UpdateThemeModel>): Promise<ThemeModel | undefined> {
    return await firstValueFrom(dialog.open(ThemeDialogComponent, config).afterClosed());
  }

  ngAfterViewInit() {
    this.wizard.showWizardActionButtons = false;
    this.themingService.themeUpdate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => this.dialogRef.close(x));
    this.wizardActionButtons = this.wizard.buttons;
  }
}
