import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicTableComponent, IColumn, TableExpandableComponent } from '@shared/ui/dynamic-table';
import { Observable } from 'rxjs';
import { FontService } from '../../core/font.service';
import { AllFontSelection, DEFAULT_FONTS, FONT_WEIGHT, FontMeta, FontSelection, FontVariant } from '../../shared/models/font.model';
import { GoogleFontSelectorComponent } from '../google-font-selector/google-font-selector.component';
import { ThemeEditorService } from '../theme-editor.service';

interface ICustom {
  edit: boolean;
}

@Component({
  selector: 'lsf-themes-editor-font-picker',
  templateUrl: './font-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    MatIconModule,
    DynamicTableComponent,
    TableExpandableComponent,
    NgIf,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgStyle,
    MatButtonModule,
    MatTooltipModule,
    NgFor,
    MatOptionModule,
    MatCheckboxModule,
    MatSelectModule,
    AsyncPipe,
  ],
})
export class FontPickerComponent implements OnInit {
  columnDefinitions: Array<IColumn<FontSelection, ICustom>> = [
    {
      name: 'target',
      text: 'Type',
      sortFunc: null,
      filterFunc: null,
    },
    {
      name: 'fontFamily',
      text: 'Font type',
      unhideable: true,
      sortFunc: null,
      filterFunc: null,
    },
    {
      name: 'variant',
      text: 'Variant',
      sortFunc: null,
      filterFunc: null,
    },
    {
      name: 'fontSize',
      text: 'Font size',
      sortFunc: null,
      filterFunc: null,
    },
    {
      name: 'lineHeight',
      text: 'Line height',
      sortFunc: null,
      filterFunc: null,
    },
    {
      name: 'letterSpacing',
      text: 'Letter spacing',
      sortFunc: null,
      filterFunc: null,
    },
    {
      name: 'edit',
      text: '',
      type: 'icon',
      widths: 'icon',
      sortFunc: null,
      filterFunc: null,
      toolTip: 'Edit font',
      valueFunc: () => 'edit',
    },
  ];

  fonts: AllFontSelection = {};
  tableData: FontSelection[] = [];

  variants = Object.values(FontVariant);
  fontWeight = FONT_WEIGHT;

  searchItems?: Observable<FontMeta[]>;

  search = '';

  editing: FontSelection = null!;
  editingClone: FontSelection = null!;

  constructor(
    private themeEditorService: ThemeEditorService,
    private dialog: MatDialog,
    private fontService: FontService,
    private changeDetectorRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {
    this.themeEditorService.theme$.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => {
      this.setFonts();
    });
  }

  searchAllFonts(family: string) {
    this.searchItems = this.fontService.getFonts(undefined, family);
  }

  ngOnInit() {
    this.themeEditorService.fonts$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => {
      const families = Array.from(new Set(x.map((f) => f.fontFamily)));
      families.forEach((x) => this.fontService.loadFont(x));
    });
  }

  setFontWeight(variant: FontVariant) {
    this.editing.fontWeight = this.fontWeight[variant];
  }

  onReset(form: NgForm, expandableRow: TableExpandableComponent<FontSelection>) {
    form.resetForm(this.editingClone);
    expandableRow.collapse();
  }

  onSubmit(form: NgForm, expandableRow: TableExpandableComponent<FontSelection>) {
    const row = expandableRow.row!;
    row.fontFamily = form.value.search;
    row.fontSize = form.value.fontSize;
    row.fontWeight = form.value.variant;
    row.letterSpacing = form.value.letterSpacing;
    row.lineHeight = form.value.lineHeight;
    row.variant = form.value.variant;
    expandableRow.collapse();
  }

  edit(fontSelection: FontSelection) {
    this.editing = this.fonts[fontSelection.target];
    this.search = JSON.parse(JSON.stringify(this.editing)).fontFamily;
    this.editingClone = JSON.parse(JSON.stringify(this.editing));
  }

  async onSelectFont() {
    const fontFamily = await GoogleFontSelectorComponent.open(this.dialog, { data: this.editing.fontFamily, width: '85vw' });

    if (fontFamily && fontFamily !== this.editing.fontFamily) {
      this.editing.fontFamily = fontFamily;
      this.search = fontFamily;
      this.changeDetectorRef.markForCheck();
    }
  }

  pickFont(f: FontMeta, allTypes: MatCheckbox) {
    if (allTypes.checked) {
      this.setAllTypeFontFamilies(f.family);
    } else {
      this.editing.fontFamily = f.family;
    }

    this.fontService.loadFont(f.family);
  }

  toggleAllTypes(allTypes: MatCheckboxChange) {
    const family = allTypes.checked ? this.editing.fontFamily : this.editingClone.fontFamily;
    this.setAllTypeFontFamilies(family);
  }

  private setFonts() {
    this.fonts = this.themeEditorService.theme.typographyConfig;
    this.filterStyles(this.fonts);
    this.tableData = Object.keys(this.fonts).map((x) => ({ ...this.fonts[x], target: x }));
    this.changeDetectorRef.markForCheck();
  }

  private filterStyles(fonts: AllFontSelection) {
    Object.keys(fonts).forEach((k) => {
      if (!(k in DEFAULT_FONTS)) {
        delete this.fonts[k];
      }
    });
  }

  private setAllTypeFontFamilies(family: string) {
    Object.values(this.fonts).forEach((x) => (x.fontFamily = family));
  }
}
