import { Component, ContentChild, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BackgroundColors, MainColors, TextColors } from '../../shared/models/colors.model';
import { ThemeModel } from '../../shared/models/theme.model';
import { AccessibilityPreviewComponent } from '../accessibility-preview/accessibility-preview.component';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { ThemeEditorService } from '../theme-editor.service';

@Component({
  selector: 'lsf-themes-editor-text-legibility',
  templateUrl: './text-legibility.component.html',
  imports: [ColorPickerComponent, AccessibilityPreviewComponent],
})
export class TextLegibilityComponent implements OnInit {
  mainColors: MainColors = {} as MainColors;
  textColors: TextColors = {} as TextColors;
  backgroundColors: BackgroundColors = {} as BackgroundColors;

  @ContentChild(AccessibilityPreviewComponent) accessibilityPreviewComponent: AccessibilityPreviewComponent = null!;

  constructor(
    private themeEditorService: ThemeEditorService,
    private destroyRef: DestroyRef,
  ) {
    this.themeEditorService.theme$.pipe(takeUntilDestroyed(destroyRef)).subscribe((theme) => {
      this.setColors(theme);
    });
  }

  ngOnInit() {
    const theme = this.themeEditorService.theme;
    this.setColors(theme);
  }

  private setColors(theme: ThemeModel) {
    this.mainColors = theme.mainColors;
    this.backgroundColors = theme.backgroundColors;
    this.textColors = theme.textColors;
  }
}
