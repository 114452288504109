import { NgIf } from '@angular/common';
import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FocusDirective } from '@shared/ui/directives';
import { hasRoleWithinAdminPolicy, themeNameRegExp } from '@shared/util/code';
import { UserService } from '@shared/util/user';
import { ThemingService } from '../../core/theming.service';
import { ThemeModel } from '../../shared/models/theme.model';
import { SvgPreviewComponent } from '../../shared/svg-preview/svg-preview.component';
import { ThemeEditorService } from '../theme-editor.service';

@Component({
  selector: 'lsf-themes-editor-settings',
  templateUrl: './theme-settings.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  imports: [FormsModule, MatIconModule, SvgPreviewComponent, MatFormFieldModule, MatInputModule, FocusDirective, NgIf, MatSlideToggleModule],
})
export class ThemeSettingsComponent {
  storedTheme: ThemeModel | undefined;
  hasRoleWithinAdminPolicy: boolean;
  themeNameRegexp = themeNameRegExp;
  @Input() editProjectTheme = false;

  constructor(
    private themingService: ThemingService,
    public themeEditorService: ThemeEditorService,
    destroyRef: DestroyRef,
    userService: UserService,
  ) {
    this.themeEditorService.theme$.pipe(takeUntilDestroyed(destroyRef)).subscribe((theme) => {
      this.storedTheme = this.themingService.tryGetTheme(theme.id);
    });
    this.hasRoleWithinAdminPolicy = hasRoleWithinAdminPolicy(userService.currentOrImpersonateUser.role);
  }
}
